import { render, staticRenderFns } from "./Funding.vue?vue&type=template&id=3c93d5dd&scoped=true"
import script from "./Funding.vue?vue&type=script&lang=js"
export * from "./Funding.vue?vue&type=script&lang=js"
import style0 from "./Funding.vue?vue&type=style&index=0&id=3c93d5dd&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c93d5dd",
  null
  
)

export default component.exports